@import url("https://fonts.cdnfonts.com/css/cera-round-pro");
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Cera Round Pro", sans-serif;
}
body {
  min-height: 100vh;
  position: relative;
  background-color: rgb(226, 226, 226);
}
.marginTop {
  margin-top: 60px;
}
.btn {
  padding: 12px 20px;
  outline: 0;
  border: 0;
  background: #3ad531;
  border-radius: 30px;
  color: white;
  z-index: 1;
  cursor: pointer;
  font-weight: bold;
  width: max-content;
  margin: 10px 0;
}
.page-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.footer {
  width: 100%;
  padding: 20px;
  background: #001689;
}
.footer p {
  font-style: normal;
  font-weight: 400;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
}
.grid {
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  flex: 1;
  margin: 10px 0;
}
.small-card {
  background: rgba(47, 191, 39, 0.15);
  border-radius: 20px;
  position: relative;
  vertical-align: middle;
  align-self: center;
  border-radius: 10px;
  overflow: hidden;
  margin: 10px;
  padding: 10px;
  width: 200px;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  align-self: center;
  box-shadow: 6px -6px 0 white, -6px 6px 0 #185c141c;
}

.small-card span {
  text-align: center;
}
.small-card span:first-child {
  font-size: 32px;
}

.small-card span:last-child {
  font-size: 16px;
}

.book-card {
  background: rgba(47, 191, 39, 0.15);
  border-radius: 20px;
  position: relative;
  vertical-align: middle;
  align-self: center;
  border-radius: 10px;
  overflow: hidden;
  margin: 10px;
  padding: 10px;
  width: 200px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  align-self: center;
}
.book-card img {
  height: 100%;
}
